@media screen  and (max-width: 640px){
  .marco-chat-izquierda{
    height: 90vh;
  }

  .roomlist .list-unstyled{
    flex-wrap: wrap;
  }

  .roomlist .list-unstyled .col-md-12{
    width: 100vw;
  }
  .roomlist .list-unstyled hr{
    margin-top: 15px;
  }

  .roomlist .list-unstyled .col-md-12 .row{
    flex-wrap: nowrap;
  }

  .roomlist .list-unstyled .col-md-12 .row .col-md-3{
    width: 20vw;
  }
  .roomlist .list-unstyled .col-md-12 .row .col-md-9{
    width: 80vw;
  }

  .marco-chat-form .col-md-12{
    width: 100vw;
  }

  .marco-chat-izquierda.hidden {
    display: none !important;
  }

  .input-message{
    width: 80vw;
  }

  .input-message {
    width: 80vw;
  }
  .button-message {
    width: 20vw;
  }

  .list-unstyled .mensaje-derecha .col-md-4{
    max-width: 70%;
  }

  .list-unstyled div.col-md-9 h5{
    font-size: 20px !important;
    color: #ececec;
  }
  .list-unstyled div.col-md-9 h6{
    font-size: 16px !important;
    color: #989898;
  }
}

@media screen  and (min-width: 641px){
  .marco-boton-regresar-chat{
    display: none !important;
  }

  .marco-mensajes{
    height:82vh !important;
  }

  .marco-mensajes .chatlist{
    height:82vh !important;
  }
}

@media (max-width: 360px){
  .imagen-chat {
    width: 200px !important;
  }

  .input-message {
    width: 80vw;
    padding-left: 10px;
  }

}

.list-unstyled .mensaje-derecha{
  justify-content: end;
}

.list-unstyled .mensaje-derecha .col-md-4{
  width: fit-content;
}

.list-unstyled p{
  font-weight: 400;
  color: black;
  user-select: none;
  font-size: 14px;
}