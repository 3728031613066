a.boton-circular{
  background: #f0ecec; 
  width:40px; 
  height: 40px;
  justify-content: center; 
  align-items: center; 
  display: flex
}

a.boton-circular:hover{
  background: #f0ecec; 
}

a i{
  font-size:18px
}


.navbar-header a:hover{
  cursor: pointer;
}

@media screen  and (max-width: 640px){
  .navbar-header{
    width: 100%;
    padding-top: 5px;
  }
  .marco-user-redes{
    width: 100%;
  }

  .marco-menu-botones{
    zoom: 80%
  }

  .roomlist{
    width:100vw
  }
}

@media (max-width: 1200px){
  .container{
    max-width: 80%;
  }
}

@media (max-width: 992px){
  .container{
    max-width: 95%;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 98%;
  }
}