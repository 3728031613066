.register-account{
    background: url(http://cdn.paper4pc.com/images/grunge-texture-wallpaper-5.jpg) no-repeat center top;
    background-color: transparent;
    background-image: url(http://cdn.paper4pc.com/images/grunge-texture-wallpaper-5.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: auto auto;
    background-size: cover;
    padding: 50px 0 50px;
    padding-left: 0px;
    width: auto;

}

/*Login Form CSS*/
.login-form input{
width: 100%;
border: 1px solid #dddddd;
padding: 0.7em;
margin-top: 0.6em;
margin-bottom: 0px;
border-radius: 0;
}
.login-form input[type='password'], .login-form input[type='date']{
  width: 49%;
  margin-right: 1%;
  padding: 0.7em;
  margin-top: 0px;
  margin-bottom: 0px;
}
.login-form select{
  width: 95%;
  margin-right: 1%;
  padding: 0.7em;
  margin-top: 0px;
  margin-bottom: 0px;
}

.login-form  h6{
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 10px;
}

.login-form{
margin-bottom: 30px;
} 
.signform {
background-color: #FFF;
padding-bottom: 40px;
padding-top: 35px;
margin: 0 auto;
margin-top: auto;
border-radius: 5px;
}
.bts {
padding: 2em;
padding-left: 5px;
padding-right: 5px;
margin: 0.6em 0;
margin-top: 
}
.bts-a:hover{
color: #79b42b;
text-decoration: none;
background: white;   
}
.bts span {
text-align: center; 
font-size: .9em;
font-family: 'Arimo', sans-serif;
font-weight: 700;
font-style: normal;
}
.social{
transition: background 200ms ease-in-out 0s;
-webkit-transition: background 200ms ease-in-out 0s;
-moz-transition: background 200ms ease-in-out 0s;
-o-transition: background 200ms ease-in-out 0s;
-ms-transition: background 200ms ease-in-out 0s;

margin-top: 12px;
-webkit-border-top-left-radius:1px;
-moz-border-radius-topleft:1px;
border-top-left-radius:1px;
-webkit-border-top-right-radius:1px;
-moz-border-radius-topright:1px;
border-top-right-radius:1px;
-webkit-border-bottom-right-radius:1px;
-moz-border-radius-bottomright:1px;
border-bottom-right-radius:1px;
-webkit-border-bottom-left-radius:1px;
-moz-border-radius-bottomleft:1px;
border-bottom-left-radius:1px;
text-indent:0;
display:block;
color:#ffffff;
height:50px;
line-height:50px;
width: 100%;
text-decoration:none;
text-align:center;
}

.fblogin {   
background-color:#3b5898;  
}
.fblogin:hover {
background-color:#5177c2;
}
.fblogin:active {
position:relative;
top:1px;
}
.gplogin {  
background-color:#dd4c39; 
}
.gplogin:hover {
background-color:#f06e60;
}
.gplogin:active {
position:relative;
top:1px;
}
.twlogin { 
background-color:#00abee;  
}
.twlogin:hover {
background-color:#4cbde6;
}
.twlogin:active {
position:relative;
top:1px;
}
.login-form input:focus, .login-form input:active {
border-bottom: 2px solid #79B42B;
outline: none;
}
.subbt:hover {
background-color: #79b42b;
}
.subbt {

text-shadow: 0 1px 0 rgba(122, 122, 122, 0.85);
transition: background 500ms ease-in-out 0s;
-webkit-transition: background 500ms ease-in-out 0s;
-moz-transition: background 500ms ease-in-out 0s;
-o-transition: background 500ms ease-in-out 0s;
-ms-transition: background 500ms ease-in-out 0s;
background-color: #79b42b;
border: none;
color: #FFF;
padding: 10px 15px 10px 15px;
margin-top: 10px;
cursor: pointer;
font-size: .9em;
border-radius: 3px;
width: 218px;
font-family: 'Arimo', sans-serif;
font-weight: 700;
font-style: normal;

}
.right a {
position: relative;
color: #b6b6b6;
text-decoration: none;
font-family: 'Arimo', sans-serif;
font-weight: 400;
font-style: normal;
font-size: .9em;
float: right;
margin-top:5px;
}
.headit { 
position: relative; 
top: -10px;
}
.headit h4{
color: #474646;
}
.bts a:hover{
text-decoration: none;
color: white;
}
.bts a:active{
color: white;
text-decoration: none;
}
.bts a:focus{
text-decoration: none;
color: white;
}
.headit a {
text-decoration: none;
}

.fa.fa-check-square {
padding-right: 19px;
}
form#login-form:before {
content: 'or';
color: #79b42b;
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
margin: auto;
height: 0.5em;
width: 0.5em;
left: 1.5em;

z-index: 900;
}
form#login-form:after {
content: '';
position: absolute;
background: rgba(128, 128, 128, 0.3);
top: 0;
right: 0;
left: 0;
bottom: 15px;
margin: auto;  
height: 3.25em;
width: 0.1em;
left: 2.15em;

-moz-box-shadow: 0 8.8em 0 0 rgba(128, 128, 128, 0.3);
-webkit-box-shadow: 0 8.8em 0 0 rgba(128, 128, 128, 0.3);
box-shadow: 0 8.8em 0 0 rgba(128, 128, 128, 0.3);
}
/*Media Query */
@media screen  and (min-width: 1500px) {
  .signform { width: 40%; left: 25%; }
  }
@media screen  and (min-width: 1300px) {
.signform { width: 50%; left: 25%; }
}


@media screen  and (max-width: 1400px) and (min-width: 1230px) {
.signform { width: 60%; left: 25%; }
}

@media screen  and (max-width: 1230px) and (min-width: 1000px) {
.signform { width: 70%; left: 15%; }
}

@media screen  and (max-width: 1000px) and (min-width: 900px) {
.signform { width: 70%; left: 10%; }
}

@media screen  and (max-width: 900px) and (min-width: 750px) {
.signform { width: 80%; left: 8%; }
}

@media screen  and (max-width: 750px) and (min-width: 640px) {
  .signform { width: 90%; 
    left: 1%; 
  }
}

@media screen  and (min-width: 640px) {
.left {   
width: 47%;
display: inline-table;
margin-left: 20px;
float: right;
}
.right {
width: 40%;
display: inline-table;
margin-left: 50px; 
}
}

@media screen  and (max-width: 640px) {
.left { 
width: 100%;
display: inline-table;  
margin-bottom: 25px;
}
.right {
width: 85%;
display: inline-table;
margin-left: 20px;  
}
.signform { 
width: 95%;
min-width:255px;
}
form#login-form:before {
content: ''; 
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
margin: auto;

}
form#login-form:after {
content: '';
position: absolute;   
top: 0;
right: 0;
left: 0;
bottom: 0;
margin: auto;
height:0em;
width: 0em;
left: 0em;
top: 0em;
}
.signform .px-4{
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.signform .bts{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.headit{
  margin-top: 40px !important;
  margin-bottom: 10px !important;
}
.bts{
  padding-top: 0px;
}
.login-form{
  margin-bottom: 0px !important;
}
.bts-a{
  margin-right: 5px !important;
}
}
@media screen  and (max-width: 1980px) and (min-width: 650px) {
form#login-form::before {
content: 'ó';
color: #79b42b;
position: absolute;   
top: 0;
right: 0;
left: 0;
bottom: 0;
margin: auto;
height: 0.5em;
width: 0.5em;
left: 1.9em;
top: 1.8em;
z-index: 900;
}
form#login-form::after {
content: '';
position: absolute;
background: rgba(128, 128, 128, 0.3);
top: 0;
right: 0;
left: 0;
bottom: 15px;
margin: auto;
height: 3.25em;
width: 0.1em;
left: 2.15em;
top: -3em;
-moz-box-shadow: 0 8.8em 0 0 rgba(128, 128, 128, 0.3);
-webkit-box-shadow: 0 8.8em 0 0 rgba(128, 128, 128, 0.3);
box-shadow: 0 8.8em 0 0 rgba(128, 128, 128, 0.3);
}
}

@media screen  and (max-width: 1980px) and (min-width: 1050px) {
.signform .px-4{
  /* padding-left: 20% !important; */
  /* padding-right: 20% !important; */
}
}
@media screen  and (max-width: 640px) and (min-width: 460px) {
.signform { 
left: 25%;
}
}
@media screen  and (max-width: 460px) and (min-width: 400px) {
.signform {
left: 20%; 
}
}
@media screen  and (max-width: 400px) and (min-width: 320px) {
.signform {
left: 10%; 
}
}
@media screen  and (max-width: 320px) {
.signform {
left: 1%;
}
}
.bts i{ 
margin: 15px 15px 15px 20px;
float: left;
width: 5%;
font-size: 20px;
margin-left: 20px;
}