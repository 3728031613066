@media screen  and (max-width: 640px){
  .botones-busqueda{
    zoom:80%
  }


  .marco-filtro{
    zoom:80%
  }
  .marco-busqueda{
    zoom:80%
  }

  .etiqueta{
    display: none;
  }

  .estadistica{
    width:40%;
  }

  .estadistica i{
    padding-left: 0px;
  }

  .tooltip-container i{
    margin-left: 1px !important;
  }

  .estadistica h3.ps-5{
    padding-left: 1.5rem !important;
  }

  .btn-compra{
    width: auto;
  }

  #pricing .container .row:first-child{
    justify-content: end;
  }
  #pricing .container .marco-resultados{
    padding-left: 0px;
    padding-right: 0px;
  }

  #pricing .container .marco-resultados-hijo{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  hr{
    width: 100%;
    margin-top: 5px;
  }

  .marco-filtro{
    flex-wrap: wrap;
  }

  .marco-filtro-1{
    width: 50%;
  }
  .marco-filtro-2{
    width: 20%;
  }
  .marco-filtro-3{
    width: 30%;
  }

  .lbl-etiqueta-1{
    display: none;
  }
  .lbl-etiqueta-2{
    display: block !important;
  }

  #selectGenero{
    margin-left: 10px;
  }

  .marco-busqueda{
    width: 70%;
    padding-top: .2rem !important;
    padding-bottom: .2rem !important;
    margin-top: 10px;
  }

  .lbl-cantidad{
    padding-top: 1px
  }
}

.lbl-cantidad{
  padding-top: 2px
}