@media screen  and (max-width: 640px){
  .marco-user-foto{
    width: 50%;
    padding-right: 2px !important;
    padding-left: 2px !important;
  }

  .foto-user-busqueda{
    height: 180px !important;
  }

  .about-overlay{
    display: none;
  }

  .marco-user-datos{
    zoom: 80%
  }
}