.modal {
    display: block;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0,0,0,0.75);
}
.modal-dialog{
  width: 100%;
  max-width: 100%;
  transform: translateY(-20%);
}

.modal-dialog .modal-content{
  border-radius: 20px;
}

.modal-header{
  margin-bottom: 40px;
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

.modal-content .form-check{
  margin-bottom: 20px;
  text-align: left !important;
  padding-left: 0px;
  align-items: center;
  display: flex;
}
.modal-content .form-check-input{
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  min-width: 25px;
}
.modal-content .form-check-label{
  color: white;
  font-size: 14px;
  margin-left: 0px;
  padding-left: 10px;
}

.modal-body{
  min-height: 300px;
}
.modal-body p{
  color: white;
  text-align: justify;
  padding-left: 100px;
  padding-right: 100px;
}
.modal-body h4{
  margin-bottom: 40px;
  color: white;
}
.modal-body .options{
  /* justify-content: center; */
}

.page {
    display: none;
}

.page.active {
    display: block;
}

.checkbox-group {
    margin-bottom: 10px;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

@media screen  and (max-width: 1100px){
  .modal-content {
    width: 95%;
  }
}

@media screen  and (max-width: 640px){
  .marco-foto-perfil{
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .modal-dialog{
    transform: translate(-2%, -5%);
  }
  .modal-content {
    width: 95%;
  }

  .form-check {
    width: 50%;
    max-width: 50%;
  }

  .modal-content .form-check-label{
    font-size: 12px;
  }

  .modal-body p{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .modal-header{
    margin-bottom: 0px !important;
  }
}