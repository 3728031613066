@media screen  and (max-width: 640px){
  .register-account{
    padding: 50px 0 150px;
  }

  .signform{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .headit {
    margin-bottom: 30px;
  }

  .signform .right{
    margin-left: 0px !important
  }
}

form#login-form:before {
    content: ''
}

form#login-form:after {
  position: relative;
}